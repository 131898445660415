ol {
    list-style-type: decimal; /* Mengatur angka untuk <ol> */
    padding-left: 20px; /* Memberikan padding kiri untuk ruang angka */
    padding-left: 20px; /* Atur padding kiri agar lebih rapi */
    margin-left: 20px;  /* Opsional: Tambah margin kiri jika diperlukan */
  }
  
  ul {
    list-style-type: disc; /* Mengatur bullet untuk <ul>, jika Anda juga ingin menambahkannya */
    padding-left: 20px; /* Memberikan padding kiri untuk ruang bullet */
    padding-left: 20px; /* Atur padding kiri agar lebih rapi */
    margin-left: 20px;  /* Opsional: Tambah margin kiri jika diperlukan */
  }
  
  li {
    margin-bottom: 5px; /* Menambahkan margin bawah pada setiap item list untuk memberi jarak */
  }
  